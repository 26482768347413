"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/table.ts
var table_exports = {};
__export(table_exports, {
  TableOpened: () => TableOpened,
  default: () => Table
});
module.exports = __toCommonJS(table_exports);
var import_luxon2 = require("luxon");

// src/cart.ts
var import_luxon = require("luxon");

// src/cart-item.ts
var CartItem = class {
  id;
  cartId;
  cartCode;
  productId;
  pizzaId;
  type;
  quantity;
  obs;
  details;
  name;
  controls;
  deleted_at;
  created_at;
  updated_at;
  constructor({ id, cartId, productId, pizzaId, type, quantity, obs, details, name, controls, deleted_at, created_at, updated_at }, cartCode) {
    ;
    this.id = id, this.cartId = cartId, this.cartCode = cartCode, this.productId = productId, this.pizzaId = pizzaId, this.type = type, this.quantity = quantity, this.obs = obs, this.details = details, this.name = name, this.controls = controls, this.deleted_at = deleted_at, this.created_at = created_at, this.updated_at = updated_at;
  }
  getTotal(withComplements) {
    if (withComplements && this.details.complements) {
      const totalComplements = this.details.complements.reduce((total, complement) => {
        complement.itens?.forEach((item) => {
          if (item.quantity) {
            total += item.value * item.quantity;
          }
        });
        return total;
      }, 0);
      return (this.details.value + totalComplements) * (this.type === "default" ? this.quantity : 1);
    } else {
      return this.details.value * (this.type === "default" ? this.quantity : 1);
    }
  }
};

// src/complements.ts
var Complement = class {
  id;
  type;
  name;
  order;
  min;
  max;
  vinculate;
  required;
  itens;
  pivot;
  created_at;
  updated_at;
  constructor(complement) {
    ;
    this.id = complement.id, this.name = complement.name, this.type = complement.type, this.order = complement.order, this.min = complement.min, this.max = complement.max, this.required = complement.required, this.itens = complement.itens ?? [], this.pivot = complement.pivot, this.created_at = complement.created_at, this.updated_at = complement.updated_at;
    this.itens.forEach((item) => {
      item.value = Number(item.value ?? 0);
      item.amount_alert = item.amount_alert ?? 0;
      item.description = item.description ?? "";
    });
  }
  /** Verifica se existem outros complementos com mesmo id e determina se é vinculado, se modifyComplements a função altera os complementos iguais
   *
   * @param {Complement[]} complements - Array de complementos
   * @param {boolean | undefined} modifyComplements - Verdadeiro ou falso para modificação dos complementos
   *
   **/
  isLinked = (complements, modifyComplements) => {
    const allComplements = complements.filter((comp) => comp.id === this.id);
    if (modifyComplements) {
      allComplements.forEach((compl) => {
        compl.name = this.name;
        compl.max = this.max;
        compl.min = this.min;
        compl.itens = this.itens;
        compl.required = this.required;
      });
    }
    return allComplements.length > 1;
  };
  getTotal() {
    let total = 0;
    for (const item of this.itens) {
      total += item.quantity ? item.value * item.quantity : item.value;
    }
    return total;
  }
  static removeProps = (complement) => {
    const { id: deletedId, created_at, updated_at, pivot, ...newComplement } = complement;
    return newComplement;
  };
  static toDuplicate(complements) {
    return complements.map((comp) => {
      return {
        name: comp.name,
        max: comp.max,
        min: comp.min,
        itens: comp.itens,
        required: comp.required,
        order: comp.order
      };
    });
  }
  static verifyEqualsComplements = (complements, complementsVerify) => {
    return complements.every((compl) => {
      return complementsVerify.some((complV) => {
        if (complV.id === compl.id && compl.itens.length === complV.itens.length) {
          return complV.itens.every((cvItem) => compl.itens.some((cItem) => cItem.code === cvItem.code && cItem.quantity === cvItem.quantity));
        }
      });
    });
  };
};

// src/cart.ts
var Cart = class _Cart {
  id;
  profileId;
  clientId;
  client;
  addressId;
  address;
  cupomId;
  cupom;
  commandId;
  command;
  bartenderId;
  bartender;
  cashierId;
  cashier;
  motoboyId;
  motoboy;
  secretNumber;
  code;
  status;
  obs;
  type;
  taxDelivery;
  timeDelivery;
  formsPayment;
  statusPayment;
  print;
  tentatives;
  total;
  controls;
  packageDate;
  created_at;
  updated_at;
  itens;
  origin;
  ifoodStatus;
  ifoodAditionalInfo;
  taxIfood;
  orderId;
  defaultStatusProductionPackage = "Ol\xE1 [NOME], seu pedido foi recebido.";
  defaultStatusProductionMessage = "Ol\xE1 [NOME], seu pedido est\xE1 em produ\xE7\xE3o.";
  defaultStatusToRemoveMessage = "Obaaa [NOME], seu pedido est\xE1 pronto para retirada.";
  defaultStatusTransportMessage = "Obaaa [NOME], seu pedido est\xE1 a caminho.";
  defaultCanceledMessage = "Ol\xE1 [NOME], seu pedido foi cancelado.";
  sendMB = false;
  constructor({
    id,
    addressId,
    address,
    bartenderId,
    bartender,
    cashierId,
    cashier,
    clientId,
    client,
    motoboyId,
    motoboy,
    commandId,
    command,
    cupomId,
    profileId,
    controls,
    formsPayment,
    itens,
    obs,
    secretNumber,
    code,
    status,
    taxDelivery,
    type,
    statusPayment,
    timeDelivery,
    total,
    print,
    tentatives,
    created_at,
    updated_at,
    packageDate,
    cupom,
    origin = "whatsmenu",
    taxIfood,
    orderId,
    ifoodStatus,
    ifoodAditionalInfo
  }) {
    this.id = id;
    this.addressId = addressId;
    this.address = address;
    this.bartenderId = bartenderId;
    this.bartender = bartender;
    this.cashierId = cashierId;
    this.cashier = cashier;
    this.clientId = clientId;
    this.client = client;
    this.motoboyId = motoboyId;
    this.motoboy = motoboy;
    this.commandId = commandId;
    this.command = command ? new Command(command) : void 0;
    this.cupomId = cupomId;
    this.cupom = cupom;
    this.profileId = profileId;
    this.controls = controls;
    this.formsPayment = formsPayment;
    this.itens = itens ? itens.map((item) => new CartItem(item, code)) : [];
    this.obs = obs;
    this.secretNumber = secretNumber;
    this.code = code;
    this.status = status;
    this.taxDelivery = taxDelivery;
    this.type = type;
    this.timeDelivery = timeDelivery;
    this.total = total;
    this.print = print;
    this.tentatives = tentatives;
    this.statusPayment = statusPayment;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.packageDate = packageDate;
    this.origin = origin;
    this.ifoodStatus = ifoodStatus;
    this.ifoodAditionalInfo = ifoodAditionalInfo;
    this.taxIfood = taxIfood;
    this.orderId = orderId;
    if (this.formsPayment.length === 1 && this.formsPayment[0]?.payment === "cashback") {
      this.formsPayment[0].value = this.getTotalValue("total");
    }
  }
  returnMaskedContact() {
    if (this.type === "T") {
      return "-";
    }
    if (this.client) {
      if (this.client.whatsapp.length > 10) {
        return this.client.whatsapp.replace(
          /(\d{2})(\d{5})(\d{4})/,
          "($1) $2-$3"
        );
      }
      return this.client.whatsapp.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
  }
  getTotalComplements() {
    return this.itens.filter((i) => i.type === "pizza").flatMap((i) => i.details.complements.flatMap((c) => c.itens)).reduce((total, item) => total += item.value * (item.quantity || 1), 0);
  }
  date() {
    let formatted;
    if (this.origin === "ifood") {
      formatted = `${import_luxon.DateTime.fromISO(this.packageDate, { setZone: true }).toFormat("dd/MM/yyyy")} ${import_luxon.DateTime.fromISO(this.packageDate, { setZone: true }).toFormat("ss").includes("01") ? "(SH)" : import_luxon.DateTime.fromISO(this.packageDate, { setZone: true }).toFormat(
        "HH:mm"
      )}`;
    }
    if (this.origin !== "ifood") {
      formatted = `${import_luxon.DateTime.fromSQL(this.packageDate, { setZone: true }).toFormat("dd/MM/yyyy")} ${import_luxon.DateTime.fromSQL(this.packageDate, { setZone: true }).toFormat("ss").includes("01") ? "(SH)" : import_luxon.DateTime.fromSQL(this.packageDate, { setZone: true }).toFormat(
        "HH:mm"
      )}`;
    }
    return {
      date: this.packageDate,
      onlyDate: import_luxon.DateTime.fromSQL(this.packageDate, { setZone: true }).toFormat(
        "dd/MM/yyyy"
      ),
      zero: import_luxon.DateTime.fromSQL(this.packageDate, { setZone: true }).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      formatted
    };
  }
  transshipment() {
    return this.formsPayment.reduce(
      (total, formPayment) => total += formPayment.payment === "money" ? Number(formPayment.change) ?? 0 : 0,
      0
    );
  }
  typeDeliveryText(textPackage = "Encomendas", textOnly = false) {
    let textDelivery = "";
    switch (this.type) {
      case "D":
        textDelivery = this.address && !textOnly ? `**Delivery**
\r` : "**Vou retirar no local**";
        break;
      case "P":
        textDelivery = this.address && !textOnly ? `**${textPackage}**\r
` : "**Vou Retirar no Local**";
        break;
      case "T":
        textDelivery = "**Pedido Mesa**";
        break;
    }
    return textDelivery;
  }
  getTextTypeReq() {
    return this.type;
  }
  /**
   * Retorna o nome do cliente baseado no tipo do carrinho.
   *
   * @returns {string} nome do cliente ou comanda
   */
  nameClient = () => {
    switch (this.type) {
      case "T":
        return `${this.command?.name}`;
      case "D":
        return `${this.client ? this.client.name : "Sem cadastro"}`;
      case "P":
        return `${this.client ? this.client.name : "Sem cadastro"}`;
      default:
        break;
    }
  };
  getTotalValue(sumWith) {
    let total = this.total;
    if (this.taxIfood && sumWith === "total") {
      total += this.taxIfood;
    }
    let addonTotal = 0;
    if (this.formsPayment && this.formsPayment[0] && this.formsPayment[0].addon && this.formsPayment[0].addon.status) {
      const {
        type: addonType,
        value: addonValue,
        valueType: addonValueType
      } = this.formsPayment[0].addon;
      if (addonValueType === "percentage") {
        addonTotal = addonValue / 100 * this.total;
      }
      if (addonValueType === "fixed") {
        addonTotal = addonValue;
      }
      if (addonType === "discount") {
        addonTotal = addonTotal * -1;
      }
    }
    const totalResult = total + this.taxDelivery - this.calcCupomValue() + addonTotal;
    const cashbackValue = this.formsPayment.find((form) => form.payment === "cashback")?.value ?? 0;
    switch (sumWith) {
      case "subtotal":
        return total;
      case "total":
        if (cashbackValue > totalResult) {
          return totalResult;
        }
        return totalResult - cashbackValue;
      case "lack":
        return Number(
          Math.fround(
            total + this.taxDelivery - Math.max(
              this.formsPayment.reduce(
                (total2, formPayment) => total2 += formPayment.value,
                0
              ),
              0
            ) + addonTotal
          ).toFixed(2)
        );
      case "addon":
        return addonTotal;
      case "paid":
        return this.formsPayment?.reduce(
          (total2, formPayment) => total2 += formPayment.value,
          0
        ) || 0;
      case "cashback":
        return cashbackValue;
    }
  }
  /**
   * Calcula o valor do cupom com base em seu tipo.
   *
   * @return {number}
   */
  calcCupomValue() {
    let cupomValue = 0;
    if (this.cupom) {
      switch (this.cupom.type) {
        case "percent":
          cupomValue = this.total * (Number(this.cupom.value) / 100);
          break;
        case "value":
          cupomValue = Number(this.cupom.value);
          break;
        case "freight":
          cupomValue = this.taxDelivery;
      }
    }
    return cupomValue;
  }
  static calcValuePizza(pizza, onlyPizza = false) {
    if (onlyPizza) {
      const totalImplementations = pizza.details.implementations.reduce(
        (total, implementation) => total += implementation.value,
        0
      );
      return pizza.details.value - totalImplementations;
    }
    return pizza.details.value * pizza.quantity;
  }
  permenance(report, opened = this.command?.opened) {
    let permenance;
    if (!opened) {
      return permenance;
    }
    const tableOpenDate = import_luxon.DateTime.fromSQL(
      opened.created_at
    ).toFormat("HH:mm");
    const tableCloseDate = report ? import_luxon.DateTime.fromSQL(opened.updated_at).toFormat("HH:mm") : import_luxon.DateTime.local().toFormat("HH:mm");
    const perm = opened.perm ?? import_luxon.DateTime.local().diff(import_luxon.DateTime.fromSQL(opened.created_at), "seconds").toFormat("hh'h'mm");
    return permenance = `${tableOpenDate}/${tableCloseDate} ${perm}`;
  }
  groupItens = (groupItems) => {
    return this.itens.reduce((newItems, cartItem) => {
      if (groupItems) {
        switch (cartItem.type) {
          case "default":
            const newItem = newItems.find(
              (item) => item.productId === cartItem.productId && item.obs === cartItem.obs && item.details.value === cartItem.details.value && item.details.complements.length === cartItem.details.complements.length
            );
            if (newItem) {
              const allComplements = Complement.verifyEqualsComplements(
                newItem.details.complements,
                cartItem.details.complements
              );
              if (allComplements) {
                newItem.quantity += cartItem.quantity;
              } else {
                newItems.push(new CartItem(cartItem, this.code));
              }
            } else {
              newItems.push(new CartItem(cartItem, this.code));
            }
            break;
          case "pizza":
            const pizza = newItems.find(
              (item) => item.pizzaId === cartItem.pizzaId && item.details.value === cartItem.details.value && item.details.sizeCode === cartItem.details.sizeCode && item.details.flavors.length === cartItem.details.flavors.length && item.details.implementations.length === cartItem.details.implementations.length && item.details.complements.length === cartItem.details.complements.length
            );
            const verificationOne = pizza?.details.flavors.every(
              (pizzaFlavor) => cartItem.details.flavors?.some(
                (elPizzaFlavor) => elPizzaFlavor.code === pizzaFlavor.code
              )
            );
            const verificationTwo = cartItem.details.flavors?.every(
              (pizzaFlavor) => pizza?.details.flavors?.some(
                (elPizzaFlavor) => elPizzaFlavor.code === pizzaFlavor.code
              )
            );
            const implementations = pizza?.details.implementations?.every(
              (pizzaImplementation) => cartItem.details?.implementations.some(
                (elPizzaImplementation) => elPizzaImplementation.code === pizzaImplementation.code
              )
            );
            if (verificationOne && verificationTwo && implementations && pizza) {
              pizza.quantity += cartItem.quantity;
            } else {
              newItems.push(new CartItem(cartItem, this.code));
            }
            break;
        }
      } else {
        newItems.push(new CartItem(cartItem, this.code));
      }
      return newItems;
    }, []);
  };
  async setMotoboyId(id, callback) {
    this.motoboyId = id;
    callback();
  }
  static cartPrint() {
    const cartPrintTest = {
      id: 7054262,
      profileId: 1,
      clientId: 56,
      statusPayment: "paid",
      addressId: 616476,
      code: "999",
      status: null,
      taxIfood: 0,
      obs: null,
      type: "D",
      taxDelivery: 5,
      timeDelivery: "10",
      formsPayment: [
        {
          flags: [],
          label: "Dinheiro",
          value: 169.5,
          status: true,
          payment: "money",
          addon: {
            status: true,
            type: "fee",
            valueType: "percentage",
            value: 10
          }
        }
      ],
      print: 1,
      tentatives: 0,
      total: 164.5,
      controls: {
        grovenfe: {
          fiscal_note: {}
        }
      },
      packageDate: null,
      created_at: "2023-07-19 14:58:54",
      updated_at: "2023-07-19 15:08:03",
      itens: [
        {
          id: 1864844,
          cartId: 7054262,
          productId: null,
          pizzaId: 1679,
          type: "pizza",
          quantity: 1,
          obs: "",
          details: {
            value: 44,
            flavors: [
              {
                code: "53e5e0",
                name: "Atum",
                image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                status: true,
                values: {
                  Broto: 15,
                  Grande: 30
                },
                complements: [],
                description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                valuesTable: {
                  Broto: 15,
                  Grande: 30
                },
                implementations: []
              },
              {
                code: "a0b3c5",
                name: "Frango com Catupiry",
                image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                status: true,
                values: {
                  Broto: 22,
                  Grande: 44
                },
                complements: [],
                description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                valuesTable: {
                  Broto: 22,
                  Grande: 44
                },
                implementations: []
              }
            ],
            complements: [],
            implementations: [
              {
                code: "cf2e08",
                name: "Borda de Catupiry",
                value: 5,
                status: true
              }
            ]
          },
          name: "Pizza Grande 2 Sabores Atum,Frango com Catupiry com Borda de Catupiry",
          controls: {},
          deleted_at: null,
          created_at: "2023-07-19 14:58:54",
          updated_at: "2023-07-19 14:58:54"
        },
        {
          id: 1864845,
          cartId: 7054262,
          productId: null,
          pizzaId: 1679,
          type: "pizza",
          quantity: 1,
          obs: "",
          details: {
            value: 44,
            flavors: [
              {
                code: "a0b3c5",
                name: "Frango com Catupiry",
                image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                status: true,
                values: {
                  Broto: 22,
                  Grande: 44
                },
                complements: [],
                description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                valuesTable: {
                  Broto: 22,
                  Grande: 44
                },
                implementations: []
              },
              {
                code: "53e5e0",
                name: "Atum",
                image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                status: true,
                values: {
                  Broto: 15,
                  Grande: 30
                },
                complements: [],
                description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                valuesTable: {
                  Broto: 15,
                  Grande: 30
                },
                implementations: []
              }
            ],
            complements: [],
            implementations: [
              {
                code: "cf2e08",
                name: "Borda de Catupiry",
                value: 5,
                status: true
              }
            ]
          },
          name: "Pizza Grande 2 Sabores Frango com Catupiry,Atum com Borda de Catupiry",
          controls: {},
          deleted_at: null,
          created_at: "2023-07-19 14:58:54",
          updated_at: "2023-07-19 14:58:54"
        },
        {
          id: 1864846,
          cartId: 7054262,
          productId: 120101,
          pizzaId: null,
          type: "default",
          quantity: 1,
          obs: "",
          details: {
            value: 11.9,
            isPromote: true,
            complements: [],
            implementations: [],
            flavors: []
          },
          name: "X-Salada",
          controls: {},
          deleted_at: null,
          created_at: "2023-07-19 14:58:54",
          updated_at: "2023-07-19 14:58:54"
        },
        {
          id: 1864847,
          cartId: 7054262,
          productId: 120101,
          pizzaId: null,
          type: "default",
          quantity: 2,
          obs: "",
          details: {
            value: 11.9,
            isPromote: true,
            complements: [],
            implementations: [],
            flavors: []
          },
          name: "X-Salada",
          controls: {},
          deleted_at: null,
          created_at: "2023-07-19 14:58:54",
          updated_at: "2023-07-19 14:58:54"
        },
        {
          id: 1864848,
          cartId: 7054262,
          productId: 120101,
          pizzaId: null,
          type: "default",
          quantity: 1,
          obs: "",
          details: {
            value: 11.9,
            isPromote: true,
            complements: [
              {
                id: 1,
                max: 10,
                min: 0,
                name: "Turbine seu Lanche",
                itens: [
                  {
                    code: "40df65",
                    name: "carne extra",
                    value: 5,
                    status: true,
                    quantity: 1,
                    description: ""
                  },
                  {
                    code: "ce5251",
                    name: "bacon",
                    value: 2,
                    status: true,
                    quantity: 1,
                    description: ""
                  }
                ],
                required: false,
                type: "default",
                order: 0
              }
            ],
            flavors: [],
            implementations: []
          },
          name: "X-Salada",
          controls: {},
          deleted_at: null,
          created_at: "2023-07-19 14:58:54",
          updated_at: "2023-07-19 14:58:54"
        },
        {
          id: 1864849,
          cartId: 7054262,
          productId: 120101,
          pizzaId: null,
          type: "default",
          quantity: 1,
          obs: "Sem tomate, por favor",
          details: {
            value: 11.9,
            isPromote: true,
            complements: [],
            implementations: [],
            flavors: []
          },
          name: "X-Salada",
          controls: {},
          deleted_at: null,
          created_at: "2023-07-19 14:58:54",
          updated_at: "2023-07-19 14:58:54"
        }
      ],
      address: {
        id: 616476,
        clientId: 56,
        street: "Rua Teste",
        number: 100,
        zipcode: null,
        complement: "AP 101",
        reference: "",
        uf: "SP",
        city: "Teste",
        neighborhood: "Bairro Teste 1",
        latitude: null,
        longitude: null,
        distance: 199288,
        controls: {},
        deleted_at: null,
        created_at: "2023-07-19 14:08:31",
        updated_at: "2023-07-19 14:08:31"
      },
      client: {
        id: 56,
        profileId: 1,
        name: "WhatsMenu",
        whatsapp: "99999999999",
        secretNumber: null,
        email: null,
        birthday_date: null,
        last_requests: [
          {
            id: 7054262,
            cart: [
              {
                obs: "",
                name: "X-Salada",
                type: "default",
                details: {
                  value: 11.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 120101
              },
              {
                obs: "",
                name: "X-Salada",
                type: "default",
                details: {
                  value: 11.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 2,
                productId: 120101
              },
              {
                obs: "",
                name: "X-Salada",
                type: "default",
                details: {
                  value: 11.9,
                  isPromote: true,
                  complements: [
                    {
                      id: 1,
                      max: 10,
                      min: 0,
                      name: "Turbine seu Lanche",
                      itens: [
                        {
                          code: "40df65",
                          name: "carne extra",
                          value: 5,
                          status: true,
                          quantity: 1,
                          description: ""
                        },
                        {
                          code: "ce5251",
                          name: "bacon",
                          value: 2,
                          status: true,
                          quantity: 1,
                          description: ""
                        }
                      ],
                      required: 0
                    }
                  ]
                },
                quantity: 1,
                productId: 120101
              },
              {
                obs: "Sem tomate, por favor",
                name: "X-Salada",
                type: "default",
                details: {
                  value: 11.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 120101
              }
            ],
            code: 7095,
            type: "D",
            total: 164.5,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: 616476,
            cartPizza: [
              {
                obs: "",
                name: "Pizza Grande 2 Sabores Atum,Frango com Catupiry com Borda de Catupiry",
                type: "pizza",
                details: {
                  value: 44,
                  flavors: [
                    {
                      code: "53e5e0",
                      name: "Atum",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                      status: true,
                      values: {
                        Broto: 15,
                        Grande: 30
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 15,
                        Grande: 30
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    },
                    {
                      code: "a0b3c5",
                      name: "Frango com Catupiry",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "4a248f",
                  complements: [],
                  implementations: [
                    {
                      code: "cf2e08",
                      name: "Borda de Catupiry",
                      value: 5,
                      status: true
                    }
                  ]
                },
                pizzaId: 1679,
                quantity: 1
              },
              {
                obs: "",
                name: "Pizza Grande 2 Sabores Frango com Catupiry,Atum com Borda de Catupiry",
                type: "pizza",
                details: {
                  value: 44,
                  flavors: [
                    {
                      code: "a0b3c5",
                      name: "Frango com Catupiry",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    },
                    {
                      code: "53e5e0",
                      name: "Atum",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                      status: true,
                      values: {
                        Broto: 15,
                        Grande: 30
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 15,
                        Grande: 30
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "4a248f",
                  complements: [],
                  implementations: [
                    {
                      code: "cf2e08",
                      name: "Borda de Catupiry",
                      value: 5,
                      status: true
                    }
                  ]
                },
                pizzaId: 1679,
                quantity: 1
              }
            ],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:58:54",
            updated_at: "2023-07-19 14:58:54",
            packageDate: null,
            taxDelivery: 5,
            timeDelivery: "10"
          },
          {
            id: 7054261,
            cart: [
              {
                obs: "",
                name: "X-Bacon",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 3,
                productId: 23267
              },
              {
                obs: "",
                name: "X-Tudo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 23268
              }
            ],
            code: 7094,
            type: "D",
            total: 39.6,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: null,
            cartPizza: [],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:49:00",
            updated_at: "2023-07-19 14:49:00",
            packageDate: null,
            taxDelivery: 0,
            timeDelivery: 0
          },
          {
            id: 7054260,
            cart: [
              {
                obs: "",
                name: "X-Bacon",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 3,
                productId: 23267
              },
              {
                obs: "",
                name: "X-Tudo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 23268
              }
            ],
            code: 7093,
            type: "D",
            total: 39.6,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: 616476,
            cartPizza: [],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:35:30",
            updated_at: "2023-07-19 14:35:30",
            packageDate: null,
            taxDelivery: 5,
            timeDelivery: "10"
          },
          {
            id: 7054259,
            cart: [
              {
                obs: "",
                name: "X-Bacon",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 3,
                productId: 23267
              },
              {
                obs: "",
                name: "X-Tudo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 23268
              }
            ],
            code: 7092,
            type: "D",
            total: 39.6,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: null,
            cartPizza: [],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:34:57",
            updated_at: "2023-07-19 14:34:57",
            packageDate: null,
            taxDelivery: 0,
            timeDelivery: 0
          },
          {
            id: 7054258,
            cart: [
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: [
                    {
                      id: 1,
                      max: 10,
                      min: 0,
                      name: "Turbine seu Lanche",
                      itens: [
                        {
                          code: "40df65",
                          name: "carne extra",
                          value: 5,
                          status: true,
                          quantity: 1,
                          description: ""
                        },
                        {
                          code: "ce5251",
                          name: "bacon",
                          value: 2,
                          status: true,
                          quantity: 1,
                          description: ""
                        }
                      ],
                      required: 0
                    }
                  ]
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 2,
                productId: 92880
              },
              {
                obs: "Sem tomate por favor",
                name: "X-Tudo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 23268
              }
            ],
            code: 7091,
            type: "D",
            total: 0,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: null,
            cartPizza: [
              {
                obs: "",
                name: "Pizza Grande 2 Sabores Atum,Frango com Catupiry ",
                type: "pizza",
                details: {
                  value: 44,
                  flavors: [
                    {
                      code: "53e5e0",
                      name: "Atum",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                      status: true,
                      values: {
                        Broto: 15,
                        Grande: 30
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 15,
                        Grande: 30
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    },
                    {
                      code: "a0b3c5",
                      name: "Frango com Catupiry",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "4a248f",
                  complements: [],
                  implementations: []
                },
                pizzaId: 1679,
                quantity: 1
              },
              {
                obs: "",
                name: "Pizza Broto 1 Sabor Quatro Queijos com Borda de Catupiry",
                type: "pizza",
                details: {
                  value: 22,
                  flavors: [
                    {
                      code: "a0dbd2",
                      name: "Quatro Queijos",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0dbd2/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Mussarela, catupiry, gorgonzola e parmes\xE3o",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "a31eb3",
                  complements: [],
                  implementations: [
                    {
                      code: "cf2e08",
                      name: "Borda de Catupiry",
                      value: 5,
                      status: true
                    }
                  ]
                },
                pizzaId: 1679,
                quantity: 1
              }
            ],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:26:43",
            updated_at: "2023-07-19 14:26:43",
            packageDate: null,
            taxDelivery: 0,
            timeDelivery: 0
          },
          {
            id: 7054257,
            cart: [
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: [
                    {
                      id: 1,
                      max: 10,
                      min: 0,
                      name: "Turbine seu Lanche",
                      itens: [
                        {
                          code: "40df65",
                          name: "carne extra",
                          value: 5,
                          status: true,
                          quantity: 1,
                          description: ""
                        },
                        {
                          code: "ce5251",
                          name: "bacon",
                          value: 2,
                          status: true,
                          quantity: 1,
                          description: ""
                        }
                      ],
                      required: 0
                    }
                  ]
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 2,
                productId: 92880
              },
              {
                obs: "Sem tomate por favor",
                name: "X-Tudo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 23268
              }
            ],
            code: 7090,
            type: "D",
            total: 0,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: null,
            cartPizza: [
              {
                obs: "",
                name: "Pizza Grande 2 Sabores Atum,Frango com Catupiry ",
                type: "pizza",
                details: {
                  value: 44,
                  flavors: [
                    {
                      code: "53e5e0",
                      name: "Atum",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                      status: true,
                      values: {
                        Broto: 15,
                        Grande: 30
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 15,
                        Grande: 30
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    },
                    {
                      code: "a0b3c5",
                      name: "Frango com Catupiry",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "4a248f",
                  complements: [],
                  implementations: []
                },
                pizzaId: 1679,
                quantity: 1
              },
              {
                obs: "",
                name: "Pizza Broto 1 Sabor Quatro Queijos com Borda de Catupiry",
                type: "pizza",
                details: {
                  value: 22,
                  flavors: [
                    {
                      code: "a0dbd2",
                      name: "Quatro Queijos",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0dbd2/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Mussarela, catupiry, gorgonzola e parmes\xE3o",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "a31eb3",
                  complements: [],
                  implementations: [
                    {
                      code: "cf2e08",
                      name: "Borda de Catupiry",
                      value: 5,
                      status: true
                    }
                  ]
                },
                pizzaId: 1679,
                quantity: 1
              }
            ],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:24:57",
            updated_at: "2023-07-19 14:24:57",
            packageDate: null,
            taxDelivery: 0,
            timeDelivery: 0
          },
          {
            id: 7054256,
            cart: [
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: [
                    {
                      id: 1,
                      max: 10,
                      min: 0,
                      name: "Turbine seu Lanche",
                      itens: [
                        {
                          code: "40df65",
                          name: "carne extra",
                          value: 5,
                          status: true,
                          quantity: 1,
                          description: ""
                        },
                        {
                          code: "ce5251",
                          name: "bacon",
                          value: 2,
                          status: true,
                          quantity: 1,
                          description: ""
                        }
                      ],
                      required: 0
                    }
                  ]
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 2,
                productId: 92880
              },
              {
                obs: "Sem tomate por favor",
                name: "X-Tudo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 23268
              }
            ],
            code: 7089,
            type: "D",
            total: 0,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: null,
            cartPizza: [
              {
                obs: "",
                name: "Pizza Grande 2 Sabores Atum,Frango com Catupiry ",
                type: "pizza",
                details: {
                  value: 44,
                  flavors: [
                    {
                      code: "53e5e0",
                      name: "Atum",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                      status: true,
                      values: {
                        Broto: 15,
                        Grande: 30
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 15,
                        Grande: 30
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    },
                    {
                      code: "a0b3c5",
                      name: "Frango com Catupiry",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "4a248f",
                  complements: [],
                  implementations: []
                },
                pizzaId: 1679,
                quantity: 1
              },
              {
                obs: "",
                name: "Pizza Broto 1 Sabor Quatro Queijos com Borda de Catupiry",
                type: "pizza",
                details: {
                  value: 22,
                  flavors: [
                    {
                      code: "a0dbd2",
                      name: "Quatro Queijos",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0dbd2/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Mussarela, catupiry, gorgonzola e parmes\xE3o",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "a31eb3",
                  complements: [],
                  implementations: [
                    {
                      code: "cf2e08",
                      name: "Borda de Catupiry",
                      value: 5,
                      status: true
                    }
                  ]
                },
                pizzaId: 1679,
                quantity: 1
              }
            ],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:10:15",
            updated_at: "2023-07-19 14:10:15",
            packageDate: null,
            taxDelivery: 0,
            timeDelivery: 0
          },
          {
            id: 7054255,
            cart: [
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: [
                    {
                      id: 1,
                      max: 10,
                      min: 0,
                      name: "Turbine seu Lanche",
                      itens: [
                        {
                          code: "40df65",
                          name: "carne extra",
                          value: 5,
                          status: true,
                          quantity: 1,
                          description: ""
                        },
                        {
                          code: "ce5251",
                          name: "bacon",
                          value: 2,
                          status: true,
                          quantity: 1,
                          description: ""
                        }
                      ],
                      required: 0
                    }
                  ]
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 92880
              },
              {
                obs: "",
                name: "X-Salada Duplo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 2,
                productId: 92880
              },
              {
                obs: "Sem tomate por favor",
                name: "X-Tudo",
                type: "default",
                details: {
                  value: 9.9,
                  isPromote: true,
                  complements: []
                },
                quantity: 1,
                productId: 23268
              }
            ],
            code: 7088,
            type: "D",
            total: 0,
            status: null,
            cupomId: null,
            clientId: 56,
            controls: {},
            addressId: null,
            cartPizza: [
              {
                obs: "",
                name: "Pizza Grande 2 Sabores Atum,Frango com Catupiry ",
                type: "pizza",
                details: {
                  value: 44,
                  flavors: [
                    {
                      code: "53e5e0",
                      name: "Atum",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/53e5e0/4.jpg",
                      status: true,
                      values: {
                        Broto: 15,
                        Grande: 30
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, atum, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 15,
                        Grande: 30
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    },
                    {
                      code: "a0b3c5",
                      name: "Frango com Catupiry",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0b3c5/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Molho, mussarela, frango, catupiry original, or\xE9gano e azeitonas",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "4a248f",
                  complements: [],
                  implementations: []
                },
                pizzaId: 1679,
                quantity: 1
              },
              {
                obs: "",
                name: "Pizza Broto 1 Sabor Quatro Queijos com Borda de Catupiry",
                type: "pizza",
                details: {
                  value: 22,
                  flavors: [
                    {
                      code: "a0dbd2",
                      name: "Quatro Queijos",
                      image: "https://s3.us-west-2.amazonaws.com/whatsmenu/production/restaurantbrazil/pizza-products/a0dbd2/4.jpeg",
                      status: true,
                      values: {
                        Broto: 22,
                        Grande: 44
                      },
                      schedule: {
                        type: 0
                      },
                      complements: [],
                      description: "Mussarela, catupiry, gorgonzola e parmes\xE3o",
                      valuesTable: {
                        Broto: 22,
                        Grande: 44
                      },
                      disponibility: {
                        store: "full"
                      },
                      implementations: []
                    }
                  ],
                  sizeCode: "a31eb3",
                  complements: [],
                  implementations: [
                    {
                      code: "cf2e08",
                      name: "Borda de Catupiry",
                      value: 5,
                      status: true
                    }
                  ]
                },
                pizzaId: 1679,
                quantity: 1
              }
            ],
            cashierId: null,
            commandId: null,
            profileId: 1,
            created_at: "2023-07-19 14:06:17",
            updated_at: "2023-07-19 14:06:17",
            packageDate: null,
            taxDelivery: 0,
            timeDelivery: 0
          }
        ],
        controls: {},
        date_last_request: "2023-07-19T17:58:54.000Z",
        deleted_at: null,
        created_at: "2023-06-26 12:47:36",
        updated_at: "2023-07-19 14:58:54"
      },
      cashier: null,
      sendMB: false
    };
    return new _Cart(cartPrintTest);
  }
  /**
   * Creates a Cart instance from an Ifood order and a profile.
   *
   * @param {IfoodOrderType} order - The Ifood order object
   * @param {Profile | ProfileType} profile - The profile associated with the order
   * @return {Cart} A new Cart instance created from the Ifood order and profile
   */
  static fromIfood({
    order,
    profile
  }) {
    let type;
    let address;
    let taxIfood = 0;
    let cupom;
    let status;
    if (order.orderTiming === "IMMEDIATE" || order.orderType === "TAKEOUT") {
      type = "D";
    }
    if (order.orderTiming === "SCHEDULED") {
      type = "P";
    }
    if (order.orderType === "TAKEOUT") {
      address = null;
    }
    if (order.orderType === "DELIVERY") {
      address = {
        city: order.delivery.deliveryAddress.city,
        neighborhood: order.delivery.deliveryAddress.neighborhood,
        street: order.delivery.deliveryAddress.streetName,
        number: order.delivery.deliveryAddress.streetNumber,
        complement: order.delivery.deliveryAddress.complement,
        reference: order.delivery.deliveryAddress.reference,
        zipcode: order.delivery.deliveryAddress.postalCode,
        latitude: order.delivery.deliveryAddress.coordinates.latitude,
        longitude: order.delivery.deliveryAddress.coordinates.longitude,
        uf: order.delivery.deliveryAddress.state
      };
    }
    if (order.total.additionalFees) {
      taxIfood = order.total.additionalFees;
    }
    if (order.total.benefits) {
      cupom = {
        value: order.total.benefits,
        type: "value"
      };
    }
    switch (order.orderStatus) {
      case "PREPARATION_STARTED":
        status = "production";
        break;
      case "CONCLUDED":
      case "DISPATCHED":
      case "READY_TO_PICKUP":
        status = "transport";
        break;
      case "CANCELLED":
        status = "canceled";
        break;
      default:
        status = null;
        break;
    }
    const ifoodCart = {
      id: order.id,
      orderId: order.orderId,
      origin: "ifood",
      ifoodStatus: order.orderStatus,
      ifoodAditionalInfo: order.additionalInfo,
      obs: order.extraInfo,
      address,
      print: order.additionalInfo?.print || 0 || 1,
      profileId: profile.id,
      code: order.displayId,
      taxIfood,
      cupom,
      client: {
        name: order.customer.name,
        whatsapp: order.customer.phone.number,
        codeLocalizer: order.customer.phone.localizer
      },
      clientId: 999,
      secretNumber: order.customer.secretNumber ? order.customer.secretNumber : null,
      status,
      type,
      taxDelivery: order.total.deliveryFee,
      timeDelivery: order.delivery ? order.delivery.deliveryDateTime : "0",
      formsPayment: order.payments[0].methods.map(
        (formPayment, index) => {
          let payment;
          let label;
          switch (formPayment.method) {
            case "CASH":
              payment = "money";
              label = "Dinheiro";
              break;
            case "CREDIT":
              payment = "credit";
              label = "Cr\xE9dito";
              break;
            case "DEBIT":
              payment = "debit";
              label = "D\xE9bito";
              break;
            case "MEAL_VOUCHER":
              payment = "snack";
              label = "Vale Refei\xE7\xE3o";
              break;
            case `FOOD_VOUCHER`:
              payment = "food";
              label = "Vale Alimenta\xE7\xE3o";
              break;
            case "GIFT_CARD":
              payment = "gift_card";
              label = "Gift Card";
              break;
            case "DIGITAL_WALLET":
              payment = "digital_wallet";
              label = "Carteira Digital";
            case "PIX":
              payment = "pix";
              label = "Pix";
              break;
            default:
              payment = "others";
              label = "Outros";
          }
          return {
            flag: formPayment.card?.brand ?? null,
            change: formPayment.cash ? formPayment.cash?.changeFor : null,
            label,
            value: formPayment.value,
            payment
          };
        }
      ),
      tentatives: 0,
      total: order.total.subTotal,
      controls: {
        pickupCode: order.delivery ? order.delivery.pickupCode : null
      },
      statusPayment: order.payments[0].methods.some(
        (method) => method.type === "OFFLINE"
      ) ? "offline" : "paid",
      packageDate: import_luxon.DateTime.fromISO(order.additionalInfo?.schedule?.deliveryDateTimeEnd, {
        zone: profile.timeZone
      }) || null,
      created_at: import_luxon.DateTime.fromISO(order.createdAt).toSQL(),
      updated_at: order.updatedAt,
      itens: order.itens.map((item) => ({
        cartId: order.id,
        controls: {},
        created_at: import_luxon.DateTime.fromISO(item.createdAt).toSQL(),
        updated_at: import_luxon.DateTime.fromISO(item.updatedAt).toSQL(),
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        productId: item.uniqueId,
        pizzaId: null,
        type: "default",
        obs: item.observations || "",
        details: {
          complements: item.complements?.reduce((acc, complement) => {
            if (!complement.groupName) {
              complement.groupName = "Complementos";
            }
            const repeatedGroup = acc.findIndex(
              (group) => group.name === complement.groupName
            );
            if (repeatedGroup !== -1) {
              acc[repeatedGroup].itens.push({
                name: complement.name,
                quantity: complement.quantity,
                value: complement.unitPrice,
                status: true
              });
            } else {
              acc.push({
                name: complement.groupName,
                type: "default",
                itens: [
                  {
                    name: complement.name,
                    quantity: complement.quantity,
                    value: complement.unitPrice,
                    status: true
                  }
                ]
              });
            }
            return acc;
          }, []) ?? [],
          flavors: [],
          implementations: [],
          value: item.unitPrice
        }
      }))
    };
    return new _Cart(ifoodCart);
  }
};

// src/command.ts
var Command = class {
  id;
  tableOpenedId;
  opened;
  name;
  code;
  status;
  fees;
  formsPayment;
  carts;
  created_at;
  updated_at;
  // WS types only
  totalValue;
  subTotal;
  lack;
  paid;
  constructor(command) {
    this.id = command.id;
    this.tableOpenedId = command.tableOpenedId;
    this.opened = command.opened ? new TableOpened(command.opened) : void 0;
    this.status = command.status;
    this.name = command.name;
    this.code = command.code;
    this.fees = command.fees?.filter((fee) => fee.deleted_at === null);
    this.formsPayment = command.formsPayment;
    this.carts = command.carts?.map((cart) => new Cart(cart)) || [];
    this.created_at = command.created_at;
    this.updated_at = command.updated_at;
  }
  haveCarts = () => {
    return !!this.carts.filter((cart) => cart.status !== "canceled").length;
  };
  getTotalValue = (only = "", value = 0) => {
    const commandTotal = this.carts.reduce((commandTotal2, cart) => {
      if (cart.status !== "canceled") {
        commandTotal2 += cart.total;
      }
      return Number(Math.fround(commandTotal2).toFixed(2));
    }, 0);
    const feeTotal = this.fees.reduce((feeTotal2, fee) => {
      if (fee.status && fee.automatic) {
        if (fee.type === "percent") {
          feeTotal2 += fee.value / 100 * commandTotal;
        } else {
          feeTotal2 += fee.quantity ? fee.quantity * fee.value : 0;
        }
      }
      return Number(Math.fround(feeTotal2).toFixed(2));
    }, 0);
    const formsPaymentTotal = this.formsPayment.reduce(
      (formsPaymentTotal2, formPayment) => Number(Math.fround(formsPaymentTotal2 + formPayment.value).toFixed(2)),
      0
    );
    const total = commandTotal + feeTotal + formsPaymentTotal;
    switch (only) {
      case "":
        return total;
      case "fee":
        return feeTotal;
      case "commandFee":
        return commandTotal + feeTotal;
      case "formsPayment":
        return formsPaymentTotal;
      case "command":
        return commandTotal;
      case "lack":
        return Number(Math.fround(Math.min(commandTotal + feeTotal - formsPaymentTotal - value, 0)).toFixed(2));
      case "paid":
        return Number(Math.fround(formsPaymentTotal + value).toFixed(2));
    }
  };
  fullPaid = () => {
    return this.getTotalValue("paid") >= this.getTotalValue("commandFee");
  };
};

// src/table.ts
var Table = class {
  id;
  profileId;
  name;
  status;
  tablesOpened;
  opened;
  deleted_at;
  created_at;
  updated_at;
  constructor(table) {
    this.id = table.id;
    this.profileId = table.profileId;
    this.name = table.name;
    this.status = table.status;
    this.tablesOpened = table.tablesOpened?.map((t) => new TableOpened(t));
    this.opened = table.opened && new TableOpened(table.opened);
    this.deleted_at = table.deleted_at;
    this.created_at = table.created_at;
    this.updated_at = table.updated_at;
  }
  activeCommands = () => {
    return this.opened?.commands.filter((command) => command.status) ?? [];
  };
  haveCarts = () => {
    return !!this.activeCommands()?.some((command) => command.haveCarts());
  };
};
var TableOpened = class {
  id;
  tableId;
  table;
  status;
  fees;
  formsPayment;
  commands;
  perm;
  created_at;
  updated_at;
  // WS types only
  wsFormsPayment;
  wsPerm;
  updatedFees;
  totalValue;
  subTotal;
  lack;
  paid;
  constructor(tableOpened) {
    this.id = tableOpened.id;
    this.tableId = tableOpened.tableId;
    this.table = tableOpened.table ? new Table(tableOpened.table) : void 0;
    this.status = tableOpened.status;
    this.commands = tableOpened.commands ? tableOpened.commands.map((command) => new Command(command)) : [];
    this.fees = tableOpened.fees;
    this.formsPayment = tableOpened.formsPayment;
    this.perm = import_luxon2.DateTime.fromSQL(tableOpened.updated_at).diff(import_luxon2.DateTime.fromSQL(tableOpened.created_at), ["months", "days", "hours", "minutes"]).toFormat(`hh'h'mm`);
    this.created_at = tableOpened.created_at;
    this.updated_at = tableOpened.updated_at;
  }
  // public allFormsPayment = () => {
  //   return this.formsPayment.concat(
  //     this.commands.flatMap(command => command.formsPayment)
  //   );
  // };
  allFeesById = (feeId) => {
    return this.commands.reduce((fees, command) => {
      const haveFee = command.fees.find((fee) => fee.id === feeId);
      if (haveFee) {
        fees.push(haveFee);
      }
      return fees;
    }, []);
  };
  getUpdatedFees = (filter = false, all = false) => {
    const fees = (filter ? this.commands.filter((c) => c.status) : this.commands).reduce((fees2, command) => {
      if (command.haveCarts()) {
        command.fees.forEach((fee) => {
          if (all ?? !fee.deleted_at) {
            const haveFee = fees2.find((f) => f.code === fee.code);
            if (!haveFee) {
              if (fee.quantity) {
                fee.quantity = fee.automatic ? fee.quantity : 0;
              }
              if (fee.type === "percent") {
                const tableFee = this.fees.find((f) => f.code === fee.code);
                if (tableFee) {
                  fee = tableFee;
                }
              }
              fees2.push({ ...fee });
            } else {
              if (fee.quantity && fee.type === "fixed") {
                ;
                haveFee.quantity += fee.automatic ? fee.quantity : 0;
              }
            }
          }
        });
      }
      return fees2;
    }, []);
    return fees;
  };
  getTotalValue = (only = "", value = 0, report = false) => {
    const tableTotal = this.commands.reduce((result, command) => Number(Math.fround(result + command.getTotalValue("command")).toFixed(2)), 0);
    const feeTotal = this.getUpdatedFees(false, true).reduce((feeTotal2, fee) => {
      if (fee.status && fee.automatic) {
        if (fee.type === "percent") {
          feeTotal2 += fee.value / 100 * tableTotal;
        } else {
          feeTotal2 += fee.quantity ? fee.quantity * fee.value : 0;
        }
      }
      return Number(Math.fround(feeTotal2).toFixed(2));
    }, 0);
    const formsPaymentTotal = this.formsPayment.reduce(
      (formsPaymentTotal2, formPayment) => Number(Math.fround(formsPaymentTotal2 + formPayment.value).toFixed(2)),
      0
    );
    const total = tableTotal + feeTotal + formsPaymentTotal;
    switch (only) {
      case "":
        return total;
      case "fee":
        return feeTotal;
      case "tableFee":
        return tableTotal + feeTotal;
      case "formsPayment":
        return formsPaymentTotal;
      case "table":
        return tableTotal;
      case "lack":
        return Number(Math.fround(Math.max(tableTotal + feeTotal - formsPaymentTotal - value, 0)).toFixed(2));
      case "paid":
        return Number(Math.fround(formsPaymentTotal + value).toFixed(2));
    }
  };
  getCarts = () => {
    return this.commands.reduce((carts, command) => {
      command.carts.forEach((cart) => {
        if (cart.status !== "canceled") {
          carts.push(cart);
        }
      });
      return carts;
    }, []);
  };
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  TableOpened
});
